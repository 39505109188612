import Highway from '@dogstudio/highway';
import Globals from '../components/global/global';


class WorkView extends Highway.Renderer{
  onEnter() {    
    
  }
  onLeave(){

  }
  onEnterCompleted(){
    console.log('Work')
    const globals = new Globals();
  }
  onLeaveCompleted(){

  }
}
 
export default WorkView;
 