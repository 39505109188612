import Highway from '@dogstudio/highway';
import Globals from '../components/global/global';
import HomeServices from '../components/home/services';


class HomeView extends Highway.Renderer{
  onEnter() {    
    
  }
  onLeave(){

  }
  onEnterCompleted(){
    console.log('Home')
    const globals = new Globals();
    const services = new HomeServices();
  }
  onLeaveCompleted(){

  }
}
 
export default HomeView;
 