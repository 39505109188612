import Highway from '@dogstudio/highway';
import gsap from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
const imagesLoaded = require('imagesloaded');
gsap.registerPlugin(ScrollTrigger);

// SLIDE
class Slide extends Highway.Transition {
  in({ from, to, done }) {    
    document.querySelector('body').className = to.getAttribute('class');
    window.scrollTo(0, 0);
    // Remove Old View and section body_class
    from.remove(); 
    document.querySelector('main > div').className = '';

    if(document.querySelector('link[rel="canonical"]'))
      document.querySelector('link[rel="canonical"]').setAttribute('href', window.location.href);
      
    // Animation    
    let tl = gsap.timeline(),     
        mySplitText = null,
        chars = null;     
    let h1Width = '';   
    
    if(document.querySelector('h1')){      
      mySplitText = new SplitText("h1", { type: "words" });
      chars = mySplitText.words;
    }    

    gsap.set(document.querySelector('main'), {opacity: 0});
    gsap.set('.hero p, .hero button, .main-header', {opacity: 0, y: 10});

    imagesLoaded( document.querySelector('body'), function( instance ) {
      gsap.to(document.querySelector('.load-screen'), {opacity: 0, duration:.3, ease:"power4.inOut", onComplete: function(){
          gsap.set(document.querySelector('body'), {overflow: 'inherit'});
          gsap.set(document.querySelector('.load-screen'), {x:'100vw', opacity: 1});
          // gsap.set(document.querySelector('.load-screen .mask'), {x: '0'});
          gsap.to(document.querySelector('main'), {opacity: 1, duration: .1});
          gsap.to('.main-header', {opacity: 1,y: 0,})
          if(document.documentElement.clientWidth < 550){
            h1Width = '100px';
          }          
          else{
            h1Width = '256px';
          }
          if(document.querySelector('h1')){
            if(document.body.classList.contains('single-post')){
              gsap.to('h1', {'--width': h1Width});
            }
            else{
              tl.from(chars, {
                duration: .5,
                opacity: 0,
                // scale: 0,
                y: 20,
                delay: 1,
                transformOrigin: "0% 50% -50",
                ease: "Power4.inOut",
                stagger: 0.5,
                onComplete: () => {
                    gsap.to('h1', {'--width': h1Width})
                    gsap.fromTo('.hero p, .hero button', {
                        opacity: 0,
                        y: 10
                    },
                    {
                        opacity: 1,
                        y: 0,
                        stagger: {
                            each: 0.2,
                        }
                    })
                }
              });  
            }
          }             
          document.body.style.pointerEvents = 'all';
          done();          
      }})
    }); 
  }  
  
  out({ from, done }) {
    // Animation    
    document.body.style.pointerEvents = 'none';
    // gsap.to(document.querySelector('.load-screen .mask'), {x: '-100vw', duration: 0.5, ease:"power4.inOut"});
    gsap.set(document.querySelector('body'), {overflow: 'hidden'});
    gsap.to(document.querySelector('.load-screen'), {x: 0, duration:0.5, delay: 0.1, ease:"power4.inOut", onComplete: function(){ 
      // Reset Scroll
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'instant'
      });
      ScrollTrigger.refresh();
      done();
      // gsap.to(document.querySelector('.load-screen'), {opacity: 1, x: "100vw", duration:0.5, ease:"power4.out", onComplete: done})      
      setTimeout(() => {
        // done();
      }, 1000);      
    }})
  }
  
}

export default Slide;

