import Highway from '@dogstudio/highway';
import Globals from '../components/global/global';


class BlogView extends Highway.Renderer{
  onEnter() {        
  }
  onLeave(){

  }
  onEnterCompleted(){    
    const globals = new Globals();

    // const lazyImages = [].slice.call(document.querySelectorAll("source.lazy"));
    // if ("IntersectionObserver" in window) {
    //   let lazyImageObserver = new IntersectionObserver(function(entries, observer) {
    //     entries.forEach(function(entry) {
    //       if (entry.isIntersecting) {
    //         let lazyImage = entry.target;
    //         lazyImage.srcset = lazyImage.dataset.srcset;
    //         lazyImage.classList.remove("lazy");
    //         lazyImageObserver.unobserve(lazyImage);
    //       }
    //     });
    //   });

    //   lazyImages.forEach(function(lazyImage) {
    //     lazyImageObserver.observe(lazyImage);
    //   });
    // } else {
    //   // Possibly fall back to event handlers here
    // }
  }
  onLeaveCompleted(){

  }
}
 
export default BlogView;
 