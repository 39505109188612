import Highway from '@dogstudio/highway';
import Globals from '../components/global/global';
import gsap from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';

class CareersView extends Highway.Renderer{
  onEnter() {        
  }
  onLeave(){

  }
  onEnterCompleted(){    
    const globals = new Globals();
    // setTimeout(() => {        
    //     whr(document).ready(function(){
    //         whr_embed(566418, {detail: 'titles', base: 'jobs', zoom: 'country', grouping: 'none'});
    //     });
    // }, 500);
    // setTimeout(() => {
    //     let whrTitle = document.querySelectorAll('.whr-title a');
    //     whrTitle.forEach( el => {
    //         el.setAttribute('target', '_blank');
    //     });
    // }, 1000);
    
    
    // document.querySelectorAll('.jobs .position').forEach(element => {
    //   element.querySelector('.see-all').classList.remove('scroll-up');
    // })
    document.querySelectorAll('.filter span').forEach(filter => {
      filter.addEventListener('mouseenter', (event) => {
        event.target.classList.add('hover');
      })
      filter.addEventListener('mouseleave', (event) => {
        event.target.classList.remove('hover');
      })      
      filter.addEventListener('click', (event) => {
        if(!event.target.classList.contains('active')){
          let filter = event.target.getAttribute('data-dep');
          if (!event.target.getAttribute('data-dep')){
            filter = 'all';
          }
          console.log(filter);
          document.querySelector('.filter span.active').classList.remove('active');
          event.target.classList.add('active');

          document.querySelectorAll('.jobs .position').forEach(element => {
            if(filter == 'all'){
              gsap.set(element, {display:'inherit'});
            }
            else{
              if(element.getAttribute('data-dep') != filter){
                gsap.set(element, {display:'none'});
              }
              else{
                gsap.set(element, {display:'inherit'});
              }
            }                        
          });
        }    
        ScrollTrigger.refresh();    
      })
    });
  }
  onLeaveCompleted(){

  }
}
 
export default CareersView;
 