import Highway from '@dogstudio/highway';
import { gsap } from 'gsap'; 
import {ScrollTrigger} from 'gsap/ScrollTrigger';
// import {ScrollSmoother} from "./util/ScrollSmoother";
const imagesLoaded = require('imagesloaded');

import Slide from './transitions/slide';
import HomeView from './views/home';
import WorkView from './views/work';
import ServicesView from './views/services';
import AboutView from './views/about-us';
import SinglePortfolioView from './views/single-portfolio';
import SingleServiceView from './views/single-service';
import CareersView from './views/careers';
import BlogView from './views/blog';
gsap.registerPlugin(ScrollTrigger);


/**
 * SASS
 */
import '../sass/app.scss';
import 'swiper/css';

/**
 * Highway Core OBJ
 */
const H = new Highway.Core({
    renderers: {
        'home': HomeView,        
        'work': WorkView,  
        'services': ServicesView,        
        'about-us': AboutView,   
        'single-portfolio': SinglePortfolioView,
        'single-service': SingleServiceView,
        'careers': CareersView,
        'blog': BlogView,
        'single-blog': BlogView,
    },
        transitions: {
        default: Slide,
    }
});

// H.on('NAVIGATE_OUT', ({ from, trigger, location }) => {    
//     window.scrollTo({
//         top: 0,
//         left: 0,
//         behavior: 'instant'
//     });
//     ScrollTrigger.refresh();
// });

H.on('NAVIGATE_OUT', ({ from, trigger, location }) => {
    ScrollTrigger.refresh();
    gsap.set('body', {overflow: 'inherit'});
});

/** UPDATE ALL DOM LINKS FOR HIGHWAY WHEN ADDED TO DOM BY AJAX **/
Object.defineProperty(Highway, 'updateLinks', {
    value: () => {
      const allLinks = document.body.querySelectorAll('a');      
    //   H.detach(allLinks);
      H.attach(allLinks);
    }
});

/**
 * JavaScript
 */
//INITIAL ANIMATION ON REFRESH AND INIT CLASSES SET UP
document.addEventListener("DOMContentLoaded", () => {
    let tl = gsap.timeline(),     
        mySplitText = null,
        chars = null; 
    let h1Width = '';

    // let h1After = CSSRulePlugin.getRule(".h1:after");

    if(document.querySelector('h1')){
        mySplitText = new SplitText("h1", { type: "words" });
        chars = mySplitText.words;
        gsap.set('.hero p, .hero button, .main-header', {opacity: 0, y: 10});
    }            
    
    imagesLoaded( document.querySelector('body'), function( instance ) {      
        // window.scrollTo({
        //     top: 0,
        //     left: 0,
        //     behavior: 'instant'
        // });
        /*
        shows DOM after all images loaded
        */        
        gsap.set(document.querySelector('.load-screen'), {x: '100vw', opacity: 1})
        gsap.set(document.body, {overflow: 'hidden'});
        gsap.to(document.querySelector('.load-screen'), {opacity: 0, duration: .5, onComplete: () => {
            gsap.set(document.body, {overflow: 'inherit'});
            gsap.set(document.querySelector('.load-screen'), {x:'100vw', opacity: 1});
        }});                
        if(document.documentElement.clientWidth < 550){
            h1Width = '150px';
          }          
          else{
            h1Width = '256px';
        }
        
        gsap.to('.main-header', {opacity: 1,y: 0,})

        if(chars != null){
          if(document.body.classList.contains('single-post')){
            gsap.to('h1', {'--width': h1Width});
          }
          else{
            tl.from(chars, {
              duration: .5,
              opacity: 0,
              // scale: 0,
              y: 20,
              delay: 1,
              transformOrigin: "0% 50% -50",
              ease: "Power4.inOut",
              stagger: 0.5,
              onComplete: () => {
                gsap.to('h1', {'--width': h1Width})
                gsap.fromTo('.hero p, .hero button', {
                  opacity: 0,
                  y: 10
                },
                {
                  opacity: 1,
                  y: 0,
                  stagger: {
                    each: 0.2,
                  }
                })
              }
            });     
          }          
        }           
        /*
        Remove section body_class
        */       
        document.querySelector('main > div').className = '';
    });
});
