import gsap from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import {DrawSVGPlugin} from "../../util/drawSVG";
gsap.registerPlugin(ScrollTrigger, DrawSVGPlugin);

class HomeServices{    
	constructor() {
		this.data = {
            quote: document.querySelector('.quote'),
            svg: document.querySelector('.check svg')
		};
		this.init();        
	}
  
	init() {		
		console.log('Home Services Component');                                
        // gsap.set(this.data.quote.querySelector('.upper'), {
        //     autoAlpha: 0
        // });
        gsap.set(this.data.svg.querySelector('path'), {drawSVG: 0})

        ScrollTrigger.create({
            trigger: this.data.quote,
            start: "top 75%",
            // end: "bottom top",
            onEnter: () => {
                gsap.fromTo(this.data.svg.querySelector('path'), {drawSVG: '0%'}, {drawSVG: '100%', duration: .5});
                gsap.fromTo(this.data.quote.querySelector('.upper'), {y: 10, autoAlpha: 0}, {autoAlpha: 1, y:0, delay: .5});
            },
            // onLeaveBack: () => {
            //     gsap.set(this.data.svg.querySelector('path'), {drawSVG: '0%'});
            //     gsap.set(this.data.quote.querySelector('.upper'), {y: 10, autoAlpha: 0}); 
            // }
        });

        ScrollTrigger.create({
            trigger: this.data.quote,
            start: "top bottom",            
            onLeaveBack: () => {
                gsap.set(this.data.svg.querySelector('path'), {drawSVG: '0%'});
                gsap.set(this.data.quote.querySelector('.upper'), {y: 10, autoAlpha: 0}); 
            }
        });
	}    
}


export default HomeServices;