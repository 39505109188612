import Highway from '@dogstudio/highway';
import Globals from '../components/global/global';
// import Blobs from '../components/global/blobs';
import ServicesHero from '../components/services/hero';


class SingleServiceView extends Highway.Renderer{
  onEnter() {    
    
  }
  onLeave(){

  }
  onEnterCompleted(){
    console.log('Single Service')
    const globals = new Globals();
    const servicesHero = new ServicesHero();
  }
  onLeaveCompleted(){

  }
}
 
export default SingleServiceView;
 