import Swiper, { FreeMode } from 'swiper';
import gsap from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import {DrawSVGPlugin} from "../../util/drawSVG";
gsap.registerPlugin(ScrollTrigger, DrawSVGPlugin);

class ServicesHow{    
	constructor() {
		this.data = {            
      device: null,        
		};
		this.init();
	}
  
	init() {		
    console.log('HOW')
    this.svgInit();
    this.initSlider();                            
	}    

    svgInit(){        
      let that = this;        
      if(document.body.clientWidth >= 1024){
          that.data.device = 'desktop';
      }
      else{
          that.data.device = 'mobile';
      }        
      console.log(document.querySelector('.how-svg'));
      if(document.querySelector('.how-svg')){
        gsap.utils.toArray(".how-svg."+that.data.device).forEach(element => {   
          if(that.data.device == 'desktop'){                           
              ScrollTrigger.create({
                  trigger: element, 
                  // start: "center bottom",
                  // end: "bottom top",
                  onEnter: () => {
                      const pulses = gsap.timeline()
                          .fromTo(".group_1 .outter_circles, .group_1 .inner_circle", {autoAlpha:0, scale: 0}, {autoAlpha: 1, scale: 1, duration: .5, ease: "back.inOut(2)"} , .3)
                          .fromTo(".group_1 .number, .group_1 .content", {autoAlpha:0, y: 10}, {autoAlpha: 1, y:0} , .8)
                          .fromTo(".group_2 .outter_circles, .group_2 .inner_circle", {autoAlpha:0, scale: 0}, {autoAlpha: 1, scale: 1, duration: .5, ease: "back.inOut(2)"} , 1.2)
                          .fromTo(".group_2 .number, .group_2 .content", {autoAlpha:0, y: 10}, {autoAlpha: 1, y:0} , 1.7)
                          .fromTo(".group_3 .outter_circles, .group_3 .inner_circle", {autoAlpha:0, scale: 0}, {autoAlpha: 1, scale: 1, duration: .5, ease: "back.inOut(2)"} , 1.9)
                          .fromTo(".group_3 .number, .group_3 .content", {autoAlpha:0, y: 10}, {autoAlpha: 1, y:0} , 2.4)                    
                          .fromTo(".group_4 .outter_circles, .group_4 .inner_circle", {autoAlpha:0, scale: 0}, {autoAlpha: 1, scale: 1, duration: .5, ease: "back.inOut(2)"} , 2.8)
                          .fromTo(".group_4 .number, .group_4 .content", {autoAlpha:0, y: 10}, {autoAlpha: 1, y:0} , 3.3);

                          let action = gsap.timeline({repeat:0});
                      if(that.data.device == 'desktop'){                         
                          action.fromTo(".how-svg path.main_path2", {drawSVG: '0% 100%'}, {drawSVG: '100% 100%', duration: 4, ease: "linear"}).add(pulses, 0);
                      }
                      else if(that.data.device == 'mobile'){                        
                          // action.fromTo(".how-svg path.main_path2", {drawSVG: '100%'}, {drawSVG: '0%', duration: 4, ease: "linear"}).add(pulses, 0);                        
                      }
                                          
                  },                
                  onLeave: () => {
                      // gsap.set(".how-svg path.main_path", {drawSVG: 0})
                  },
                  onLeaveBack: () => {
                      // gsap.set(".how-svg path.main_path", {drawSVG: 0})
                  }                
              });
          }
          else{                
              const main = gsap.timeline({
                  scrollTrigger: {
                      trigger: ".how-svg",
                      scrub: true,
                      start: "top 70%",
                      end: "bottom -20%"
                      }
                  })
                  .fromTo(".how-svg path.main_path2", {drawSVG:'100%'}, {drawSVG: '0%', duration: 4}, 0)
                  .fromTo(".group_1 .outter_circles, .group_1 .inner_circle", {scale: 0, autoAlpha: 0}, {scale: 1, autoAlpha: 1}, 0)                    
                  .fromTo(".group_2 .outter_circles, .group_2 .inner_circle", {scale: 0, autoAlpha: 0}, {scale: 1, autoAlpha: 1}, .7)
                  .fromTo(".group_3 .outter_circles, .group_3 .inner_circle", {scale: 0, autoAlpha: 0}, {scale: 1, autoAlpha: 1}, 1.2)
                  .fromTo(".group_4 .outter_circles, .group_4 .inner_circle", {scale: 0, autoAlpha: 0}, {scale: 1, autoAlpha: 1}, 3.5)
                  
                  .fromTo(".group_1 .number", {y: 20, autoAlpha: 0}, {y: 0, autoAlpha: 1}, 0)                    
                  .fromTo(".group_1 .content", {y: 20, autoAlpha: 0}, {y: 0, autoAlpha: 1}, .4)                    
                  .fromTo(".group_2 .number", {y: 20, autoAlpha: 0}, {y: 0, autoAlpha: 1}, .7)
                  .fromTo(".group_2 .content", {y: 20, autoAlpha: 0}, {y: 0, autoAlpha: 1}, 1.1)
                  .fromTo(".group_3 .number", {y: 20, autoAlpha: 0}, {y: 0, autoAlpha: 1}, 1.2)
                  .fromTo(".group_3 .content", {y: 20, autoAlpha: 0}, {y: 0, autoAlpha: 1}, 1.6)
                  .fromTo(".group_4 .number", {y: 20, autoAlpha: 0}, {y: 0, autoAlpha: 1}, 3.5)
                  .fromTo(".group_4 .content", {y: 20, autoAlpha: 0}, {y: 0, autoAlpha: 1}, 3.9);

              
              // gsap.fromTo(".how-svg path.main_path2", {
              //     drawSVG: '100%'
              //   }, {
              //     drawSVG: "0%",
              //     ease: "none",
              //     scrollTrigger: {
              //       trigger: ".how-svg",
              //       pin: true,
              //       scrub: 1,
              //       start: "top bottom",
              //       end: "bottom top"
              //     }
              // });
          }
          
        })
      }
    }

    initSlider(){
        const swiper = new Swiper('.how .swiper', {            
            slidesPerView: 2.3,
            speed: 1000,
            modules: [FreeMode],
            freeMode: {
                enabled: true,
                // minimumVelocity: 0.2,
                momentum: true,
            },
            loop: true,
            loopedSlides: 2,
            centeredSlides: true,            
            breakpoints: {
                // when window width is >= 320px
                320: {
                  slidesPerView: 1.2,
                  centeredSlides: false,                  
                },                
                // when window width is >= 640px
                640: {
                  slidesPerView: 1.5,                  
                },
                1024: {
                    slidesPerView: 2.3,
                    centeredSlides: true,
                }
            }
        });
    }
}


export default ServicesHow;