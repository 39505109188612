import Blobs from "../global/blobs.js";
import gsap from 'gsap';
import { EasePack } from "gsap/EasePack";
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger, EasePack); 
class ServicesHero{
	constructor() {
		this.data = {
            // heroSlider: document.querySelector('.slider'), 
            blobs: new Blobs(),
			hero: document.querySelector('.hero')         
		};
		this.init();
	}
  
	init() {				
		console.log('Sevices Hero');
		if(document.querySelector('.single-service-wrapper')){
			if(document.querySelector('.single-service-wrapper').classList.contains('layout2')){
				document.querySelector('body').classList.add('layout2');			
			}
		}
		
		document.querySelector('.main-header').classList.add('services-hero')
			ScrollTrigger.create({
				trigger: this.data.hero,
				start: '95% top',
				onEnter: () => {
					this.headerBgChange(this.data.hero, 1);
				},
				onEnterBack: () => {                     
					this.headerBgChange(this.data.hero, -1);
				},
			});
		
	}    

    headerBgChange(el, direction) {
		console.log(direction)
		if(direction == 1){			
			document.querySelector('.main-header').classList.remove('services-hero')
		}
		else{
			document.querySelector('.main-header').classList.add('services-hero')
		}
	}
}


export default ServicesHero;