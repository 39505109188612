import {Swiper, Navigation, Pagination} from 'swiper';

class AboutReviews{    
	constructor() {
		this.data = {

		};
		this.init();
	}
  
	init() {
		this.initSlider();
	}    

  initSlider(){
    const swiper = new Swiper('.reviews .swiper', {      
      speed: 1000,
      navigation: {
        nextEl: '.reviews .next',
        prevEl: '.reviews .prev',
      },
      modules: [Navigation, Pagination],
      loop: true,
      pagination: {
        el: '.reviews .pagination',
        type: 'bullets',
      },
      breakpoints: {          
        0: {
          spaceBetween: 10,
          slidesPerView: 1.2,
        },                          
        640: {
          spaceBetween: 10,
          slidesPerView: 1.2,
        },
        768: {
          spaceBetween: 10,
          slidesPerView: 1,
        },
        1024: {
          spaceBetween: 10,
          slidesPerView: 1,
        },
        1380: {
          spaceBetween: 10,
          slidesPerView: 1,
        }
      }
    });
  }
    
}
export default AboutReviews;