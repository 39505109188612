import gsap from 'gsap';
class AboutHistory{    
	constructor() {
		this.data = {      
		};
		this.init();
	}
  
	init() {
		let height = document.querySelector('.history .text-20').offsetHeight;
    document.querySelector('.history .text-20').setAttribute('data-height', height);
    gsap.set('.history p', {height: 'calc(2em * 16)'})

    document.querySelector('.history  .see-all').addEventListener('click', event => {
      if(event.target.classList.contains('active')){
        event.target.classList.remove('active');
        gsap.to('.history p', {height: 'calc(2em * 16)', ease: 'power4.out'});        
      }
      else{
        event.target.classList.add('active');
        gsap.to('.history p', {height: height, ease: 'power4.out'});
        gsap.set(event.target, {autoAlpha: 0});
        gsap.set(event.target.parentNode.querySelector('.elipsis'), {autoAlpha: 0});        
      }
    })
	}    

  
    
}
export default AboutHistory;