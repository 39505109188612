import Highway from '@dogstudio/highway';
import Globals from '../components/global/global';
import ServicesHow from '../components/services/how';
import ServicesHero from '../components/services/hero';


class ServicesView extends Highway.Renderer{
  onEnter() {    
    
  }
  onLeave(){

  }
  onEnterCompleted(){
    console.log('Services')
    const globals = new Globals();
    const how = new ServicesHow();
    const hero = new ServicesHero();
  }
  onLeaveCompleted(){

  }
}
 
export default ServicesView;
 