import gsap from 'gsap';
import { EasePack } from "gsap/EasePack";
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger, EasePack);

class Blobs{
	constructor() {
		this.data = {            
            blobs: document.querySelectorAll('.blob-wrapper'),
		};
		this.init();
	}
  
	init() {         
		console.log('blobs');       

        if(this.data.blobs.length != 0){
            this.blobsAnimations();
        }
        // this.parallaxBg();       
        ScrollTrigger.refresh();        
	}

    blobsAnimations(){
        let that = this;
        gsap.utils.toArray(".blob-wrapper").forEach(element => {   
                         
            ScrollTrigger.create({
                trigger: element,
                start: "center bottom",
                // end: "bottom top",
                onEnter: () => {                            
                    if(element){
                      console.log(element)
                        gsap.fromTo(element.querySelector('.blob'), {scale: 0}, {scale:1, duration: .8, ease: 'elastic.out(1.2, 1)', delay: 1})
            
                        // gsap.set(element.querySelector('.blob'), { xPercent: -50, yPercent: -50 });
                        
                        element.addEventListener('mouseenter', function(){                        
                            gsap.to(element.querySelector('.blob'),{scale:1,autoAlpha:1, duration: .4})                
                        });
                        
                        element.addEventListener('mouseleave', function(){                            
                            gsap.to(element.querySelector('.blob'),{scale:0.1,autoAlpha:0, duration: .4, ease: 'power4.out'})
                        });

                        let relX = null;
                        let relY = null;
                        element.addEventListener('mousemove', function(e){                            
                            relX = e.pageX - element.querySelector('.blob').offsetLeft - (element.querySelector('.blob').getBoundingClientRect().width / 2);
                            relY = e.pageY - element.querySelector('.blob').offsetTop - (element.querySelector('.blob').getBoundingClientRect().height / 2);
                            gsap.to(element.querySelector('.blob'), { x: relX, y: relY, duration: .3 });
                        });
                    }                    
                },                
                // onLeave: () => {
                //     gsap.fromTo(element.querySelectorAll('.pop'), {scale: '1'}, {scale: '0', duration: .3, ease: 'linear'});                    
                // },                
            });

        })      
    }

}


export default Blobs;