import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { formatWithCursor } from 'prettier';
import { ScrollSmoother } from "../../util/ScrollSmoother";
import GlobalHeader from './global-header';
const imagesLoaded = require('imagesloaded');


const axios = require('axios').default;

// import { VelocityTracker } from "../../util/VelocityTracker.js";
// import InertiaPlugin from "../../util/inertiaPlugin";
import SplitText from "../../util/splitText";

gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

class Globals {
    constructor() {
        this.data = {
            footerFormInputs: document.querySelectorAll('.footer-wrapper #footer-form input, .footer-wrapper #footer-form textarea'),
            scrollUp: document.querySelector('.scroll-up'),
            underline: document.querySelectorAll('.underline'),
            imgMask: document.querySelector('.img-wrapper .mask'),
            menuItems: document.querySelectorAll('#menu-primary .menu-item'),
            pops: document.querySelectorAll('.pop'),
            footerForm: document.querySelector('#footer-form'),
            globalHeader: new GlobalHeader(),
            burguer: document.querySelector('.burguer')
        };
        this.init();
        let mySplitText;
        let chars;
        //NOT SURE WHY THIS IS HERE — COMMENTED TO TEST
        // gsap.utils.toArray("h5").forEach(element => {
        //   mySplitText = new SplitText(element, { type: "words" });
        //   chars = mySplitText.words;
        // })
        // this.burguerMenu = this.burguerMenu.bind(this);

        this.formLabels = this.formLabels.bind(this);
    }

    init() {

        console.log('globals');
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'instant'
        });

        // ScrollSmoother.create({
        //     smooth: 1,
        //     effects: true,
        //     smoothTouch: 0.001,
        // });

        if (this.data.scrollUp)
            this.scrollUp();
        if (this.data.underline)
            this.titlesAfterAnimation();
        if (this.data.imgMask)
            this.imgAnimation();
        if (this.data.pops)
            this.popAnimation();

        this.contactFormSubmit();
        this.menuManagement();
        // this.parallaxBg();
        this.formLabels();


        let acc = document.getElementsByClassName("item-title");
        let i;
        console.log(acc);
        for (i = 0; i < acc.length; i++) {
            acc[i].addEventListener("click", function () {
                this.classList.toggle("active");
                var panel = this.nextElementSibling;
                if (panel.style.maxHeight) {
                    panel.style.maxHeight = null;
                } else {
                    panel.style.maxHeight = panel.scrollHeight + 40 + "px";
                }
            });
        }
    }

    contactFormSubmit() {
        let that = this;
        that.data.footerForm.addEventListener('submit', event => {
            event.preventDefault();
            let formData = new FormData();

            let name = that.data.footerForm.querySelector('#name').value;
            let email = that.data.footerForm.querySelector('#email').value;
            let message = that.data.footerForm.querySelector('#message').value;
            let url = window.location.origin + '/wp-admin/admin-ajax.php';

            formData.append("name", name);
            formData.append("email", email);
            formData.append("message", message);
            formData.append('action', 'contactForm');
            axios({
                method: "post",
                url: url,
                data: formData,
                headers: { "Content-Type": "multipart/form-data" },
            })
                .then(function (response) {
                    //handle success
                    if (response.data == 200) {
                        document.querySelector('.email-notification').classList.add('success');
                        document.querySelector('.email-notification').innerHTML = "Thanks for your contact! We will get back to you as soon as possible.";
                        gsap.to(that.data.footerForm, {
                            autoAlpha: 0, duration: .3, ease: "linearOut", onComplete: () => {
                                gsap.fromTo(document.querySelector('.email-notification'), { autoAlpha: 0, y: 20 }, { autoAlpha: 1, y: 0, duration: .3 })
                            }
                        });
                    }
                })
                .catch(function (response) {
                    document.querySelector('.email-notification').classList.add('error');
                    document.querySelector('.email-notification').innerHTML = "Something went wrong. Please try again!";
                    gsap.to(that.data.footerForm, {
                        autoAlpha: 0, duration: .3, ease: "linearOut", onComplete: () => {
                            gsap.fromTo(document.querySelector('.email-notification'), { autoAlpha: 0, y: 20 }, { autoAlpha: 1, y: 0, duration: .3 })
                        }
                    });
                    //handle error
                });
        });
    }


    menuManagement() {
        let that = this;
        that.data.menuItems.forEach(element => {
            element.addEventListener('mouseenter', () => {
                event.target.classList.add('active')
            })
            element.addEventListener('mouseleave', () => {
                event.target.classList.remove('active')
            })
        });
    }

    popAnimation() {
        gsap.utils.toArray(".pop-wrapper").forEach(element => {
            ScrollTrigger.create({
                trigger: element,
                start: "top bottom",
                // end: "bottom top",
                onEnter: () => {
                    if (element.querySelector('.img-wrapper .pop')) {
                        gsap.fromTo(
                            element.querySelectorAll('.img-wrapper .pop'),
                            {
                                scale: '0'
                            },
                            {
                                scale: '1',
                                duration: .8,
                                ease: 'elastic.out(1.2, 1)',
                                stagger: {
                                    each: .3,
                                }
                            }
                        );
                    }
                },
                // onLeave: () => {
                //     gsap.fromTo(element.querySelectorAll('.pop'), {scale: '1'}, {scale: '0', duration: .3, ease: 'linear'});
                // },
            });
        })
    }

    imgAnimation() {
        gsap.utils.toArray(".img-wrapper").forEach(element => {
            ScrollTrigger.create({
                trigger: element,
                start: "top bottom",
                end: "center top",
                onEnter: () => {
                    gsap.fromTo(element.querySelector('img'), { scale: '1.1' }, { scale: '1', duration: .8, delay: .2, ease: 'power4.inOut' });
                    if (element.querySelector('.mask'))
                        gsap.fromTo(element.querySelector('.mask'), { width: '100%' }, { width: '0px', duration: .5, ease: 'power4.inOut' });
                },
                onLeaveBack: () => {
                    gsap.fromTo(element.querySelector('img'), { scale: '1' }, { scale: '1.1', duration: .5, ease: 'power4.inOut' });
                    if (element.querySelector('.mask'))
                        gsap.fromTo(element.querySelector('.mask'), { width: '0%' }, { width: '100%', duration: .5, delay: .2, ease: 'power4.inOut' });
                },
            });
        })

    }

    formLabels() {
        let that = this;
        that.data.footerFormInputs.forEach(input => {
            input.addEventListener('focus', () => {
                event.target.parentElement.classList.add('active');
            })
            input.addEventListener('focusout', () => {
                if (event.target.value.length === 0) {
                    event.target.parentElement.classList.remove('active')
                }
            })
        });
    }


    titlesAfterAnimation() {
        let afterWidth = '';
        let finalWidth = '';
        gsap.utils.toArray(".underline").forEach(element => {

            if (element.classList.contains('see-all')) {
                element.setAttribute('data-width', afterWidth);
                afterWidth = '100%';
            }
            else {
                if (document.documentElement.clientWidth < 550) {
                    // afterWidthSmall = '80px';
                    afterWidth = '150px';
                    element.setAttribute('data-width', afterWidth);
                }
                else {
                    // afterWidthSmall = '100px';
                    afterWidth = '256px';
                    element.setAttribute('data-width', afterWidth);
                }
            }

            if (element.localName != 'h1' && !element.classList.contains('round-underline')) {
                ScrollTrigger.create({
                    trigger: element,
                    onEnter: () => {
                        finalWidth = element.getAttribute('data-width');
                        gsap.fromTo(element, { '--width': '0px' }, { '--width': finalWidth, duration: 2, ease: 'power4.inOut' });
                    },
                    onEnterBack: () => {
                        finalWidth = element.getAttribute('data-width');
                        gsap.fromTo(element, { '--width': '0px' }, { '--width': finalWidth, duration: 2, ease: 'power4.inOut' });
                    },
                });
            }
            if (element.classList.contains('round-underline')) {
                ScrollTrigger.create({
                    trigger: element,
                    onEnter: () => {
                        gsap.fromTo(element, { '--scale': 0 }, { '--scale': 1, duration: 1.5, ease: 'elastic.out(1.2, 1)' });
                    },
                    onEnterBack: () => {
                        gsap.fromTo(element, { '--scale': 0 }, { '--scale': 1, duration: 1.5, ease: 'elastic.out(1.2, 1)' });
                    },
                });
            }
        })
    }

    scrollUp() {
        gsap.utils.toArray(".scroll-up").forEach(element => {
            ScrollTrigger.create({
                trigger: element,
                onEnter: () => {
                    gsap.fromTo(element, { y: 100, opacity: 0 }, { y: 0, opacity: 1, duration: 1.2, ease: "expo.out" });
                },
                onLeaveBack: () => {
                    gsap.fromTo(element, { y: 0, opacity: 1 }, { y: 100, opacity: 0, duration: 1.2, ease: "expo.out" });
                },
                onEnterBack: () => {
                    gsap.fromTo(element, { y: -100, opacity: 0 }, { y: 0, opacity: 1, duration: 1.2, ease: "expo.out" });
                },
            });
        })
    }

}


export default Globals;