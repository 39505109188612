import Highway from '@dogstudio/highway';
import Globals from '../components/global/global';


class SinglePortfolioView extends Highway.Renderer{
  onEnter() {    
    
  }
  onLeave(){

  }
  onEnterCompleted(){
    console.log('Single Portfolio')
    const globals = new Globals();
  }
  onLeaveCompleted(){

  }
}
 
export default SinglePortfolioView;
 