import gsap from 'gsap';
import { EasePack } from "gsap/EasePack";
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger, EasePack); 

class GlobalHeader{
	constructor() {
		this.data = {            
            burguer: document.querySelector('.burguer')
		};
		this.init();
        this.manageMobileMenu = this.manageMobileMenu.bind(this);    
	}
  
	init() {         
		console.log('global header component');       

        this.globalHeaderInit();
        
        this.burguerInit();

        ScrollTrigger.refresh();        

	}

    burguerInit(){
        const that = this;
        that.data.burguer.addEventListener('click', that.manageMobileMenu);        
    }
    manageMobileMenu(event) {
        if(document.querySelector('.burguer input').checked){
            gsap.set('body', {overflow: 'hidden'});
            document.querySelector('.main-header').classList.add('mobile-menu-active')
            gsap.to(document.querySelector('.mobile-menu'), {x: '0', duration:.25, delay: .1, ease: "power4.inOut"})
            gsap.to(document.querySelector('.mobile-menu-mask'), {x: '0', duration:.3, ease: "power4.inOut"})
        }
        else{
            setTimeout(() => {
                document.querySelector('.main-header').classList.remove('mobile-menu-active')
            }, 300);
            gsap.set('body', {overflow: 'inherit'});
            gsap.to(document.querySelector('.mobile-menu'), {x: '100vw', ease: "power4.inOut"})
            gsap.to(document.querySelector('.mobile-menu-mask'), {x: '100vw', delay: .2, duration:.3, ease: "power4.inOut"})
        }
    }

    globalHeaderInit() {
        const that = this;

        //ADD ICONS TO SERVICES MENU
        document.querySelectorAll('.header-menu .menu').forEach( menu => {
          menu.querySelectorAll('.header-menu .menu .menu-item').forEach( element => {
            if(element.querySelector('a').getAttribute('data-url') != null){                
                let span1 = document.createElement("span");
                let span2 = document.createElement("span");
                let desc = document.createElement("span");
                let menu_item = element.querySelector('a').innerHTML;
                element.querySelector('a').innerHTML = '';
                desc.classList.add('caption');
                let img= document.createElement("img");
                desc.innerHTML = element.querySelector('a').getAttribute('data-desc');
                img.src = element.querySelector('a').getAttribute('data-url');
                span1.append(img);
                                
                span2.innerHTML = menu_item;
                if(desc.innerHTML){
                  span2.append(desc);
                } 
                
                element.querySelector('a').prepend(span1);
                element.querySelector('a').append(span2);                                

            }            
          })
        })        

        let lastScrollTop = 0;
        let leftgear = document.querySelector(".logo .picto");
        // gsap.set('.sub-menu', {y: '-150%'})
        // document.querySelector('.menu-item-has-children').addEventListener('mouseenter', that.subMenuManagementOpen);
        // document.querySelector('.menu-item-has-children').addEventListener('mouseleave', that.subMenuManagementClose);
        window.addEventListener("scroll", function() {
            leftgear.style.transform = "rotate("+window.pageYOffset+"deg)";            
            let st = window.pageYOffset;
            if (st > lastScrollTop){
                gsap.to('header', {y: '-100%', ease: 'power4'})
                document.querySelector('.main-header').classList.add('active')
            } else {
                gsap.to('header', {y: '0%', ease: 'power4'})
                document.querySelector('.main-header').classList.remove('active')
            }
            lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
        });        
    }

    subMenuManagementOpen(event) {
        console.log('asd')
        gsap.to('.sub-menu', {y: '0%'})
    }
    subMenuManagementClose(event) {
        gsap.to('.sub-menu', {y: '-150%'})
    }

}


export default GlobalHeader;