import Highway from '@dogstudio/highway';
import Globals from '../components/global/global';
import AboutTeam from '../components/about-us/team';
import AboutReviews from '../components/about-us/reviews';
import AboutHistory from '../components/about-us/history';

class AboutView extends Highway.Renderer{
  onEnter() {    
    
  }
  onLeave(){

  }
  onEnterCompleted(){
    const team = new AboutTeam();
    const aboutReviews = new AboutReviews();
    const aboutHistory = new AboutHistory();
    const globals = new Globals();
  }
  onLeaveCompleted(){
 
  }
}
 
export default AboutView; 
 